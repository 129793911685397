import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AssistantChatService } from '../../services/assistant-chat.service';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
})
export class ChatInputComponent {

  chatForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    // Iniciar Formulario
    this.chatForm = this.formBuilder.group({
      message: [
        '',
        [
          Validators.required,
          (control: AbstractControl) => {
            // Validadar que el campo no sean espacios en blanco o saltos de linea
            const value = control.value ? control.value.trim() : control.value;
            return value == '' ? { empty: true } : null;
          },
        ],
      ],
    });
  }

  /**
   * Estados de carga del asistente
   */
  get loadingStates() {
    return AssistantChatService.loadingStates;
  }

  /**
   * Deshabilitar envio de mensaje
   */
  get disabledSend() {
    return this.loadingStates.sendingMessage.value || this.loadingStates.creatingThread.value;
  }

  /**
   * Emitir envio de mensaje
   */
  sendMessage() {
    if(this.chatForm.valid) {

      if(this.disabledSend) {
        return;
      }

      AssistantChatService.sendMessage(this.chatForm.value.message);
      this.chatForm.reset();

    }
  }
}
