import { AfterViewInit, Component, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AssistantChatService } from './services/assistant-chat.service';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';
import { ASSISTANT_STEPS } from 'src/app/core/constants/assistant-steps.steps';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-assistant-chat',
  templateUrl: './assistant-chat.component.html',
  styleUrls: ['./assistant-chat.component.scss'],
})
export class AssistantChatComponent implements AfterViewInit {
  tutorialService = inject(AppTutorialService);
  steps = ASSISTANT_STEPS;
  assistantTutorialFinalDate = environment.assistantTutorialFinalDate;

  //////// AUN FALTA POR CULMINAR:
  //////// . https://www.notion.so/uisr/c75facf6cedc40c899892fc58e0a0b41?v=8d1ba0543eac4348abc96674cf57e23d&p=1ca8878c655842118bbd0201e75f38ab&pm=s

  constructor() { }

  ngAfterViewInit(): void {
    if(AssistantChatService.assistantAvailable()) {

      AssistantChatService.fetchModels();

      setTimeout(() => {
        if (this.tutorialService.shouldStartTourFromDate('keepFirstTimeAssistant', DateTime.fromJSDate(this.assistantTutorialFinalDate))) {
          this.tutorialService.startTour(this.steps, undefined, 'assistant-tour');
        }
      }, 0);
    }
  }

  /**
   * Estados de carga del asistente
   */
  public get loadingStates() {
    return AssistantChatService.loadingStates;
  }

  /**
   * Obterne el estado del componente desde el servicio
   */
  public get componentStates() {
    return AssistantChatService.componentStates;
  }

  /**
   * Obtener la conversacion
   */
  public get thread() {
    return AssistantChatService.thread;
  }

  /**
   * Obtener el nombre de la conversacion
   */
  public get threadName() {
	  return AssistantChatService.thread?.name || '';
  }

  /**
   * Documentos de la conversacion
   */
  public get documents() {
    return AssistantChatService.documents;
  }
  
  /**
   * Modelos del asistente
   */
  public get models() {
    return AssistantChatService.models;
  }

  /**
   * Modelo actual
   */
  public get model() {
    return AssistantChatService.model;
  }

  /**
   * Establecer el modelo
   */
  public get setModel() {
    return AssistantChatService.setModel;
  }

  /**
   * Eliminar un documento
   */
  public get removeDoc() {
    return AssistantChatService.removeDoc;
  }

  /**
   * Maximizar chat
   */
  public get maximize() {
    return AssistantChatService.maximize;
  }

  /**
   * Minimizar chat
   */
  public get minimize() {
    return AssistantChatService.minimize;
  }

  /**
   * Abrir chat
   */
  public get open() {
    return AssistantChatService.open;
  }

  /**
   * Cerrar chat
   */
  public get close() {
    return AssistantChatService.close;
  }

  /**
   * Abrir informacion del asistente
   */
  public get showInfoContent() {
    return AssistantChatService.showInfoContent;
  }

  /** Realiza el llamado al servicio del tutorial para empezar el tour del asistente */
  startTour() {
    this.tutorialService.startTour(this.steps, undefined, 'assistant-tour');
  }

}
