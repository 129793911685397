import { NgModule } from '@angular/core';
import {
  matHomeOutline,
  matHelpOutline,
  matLightModeOutline,
  matDarkModeOutline,
  matLanguageOutline,
  matSettingsSuggestOutline,
  matManageAccountsOutline,
  matVisibilityOffOutline,
  matVisibilityOutline,
  matCancelOutline,
  matCreditCardOutline,
  matSettingsOutline,
  matFindInPageOutline,
  matSearchOutline,
  matExpandMoreOutline,
  matSecurityOutline,
  matPhonelinkLockOutline,
  matCloseOutline,
  matReviewsOutline,
  matRequestPageOutline,
  matReceiptLongOutline,
  matKeyboardArrowLeftOutline,
  matHandshakeOutline,
} from '@ng-icons/material-icons/outline';
import {
  matPersonSharp,
  matGroupSharp,
  matDragIndicatorSharp,
  matExpandSharp,
} from '@ng-icons/material-icons/sharp';
import { octSidebarCollapse, octSidebarExpand } from '@ng-icons/octicons';
import { NgIconsModule } from '@ng-icons/core';

const ICONS = {
  matHomeOutline,
  matHelpOutline,
  matLightModeOutline,
  matDarkModeOutline,
  matLanguageOutline,
  matSettingsSuggestOutline,
  matManageAccountsOutline,
  matVisibilityOffOutline,
  matVisibilityOutline,
  matCancelOutline,
  matCreditCardOutline,
  matSettingsOutline,
  matFindInPageOutline,
  matSearchOutline,
  matExpandMoreOutline,
  matSecurityOutline,
  matPhonelinkLockOutline,
  matCloseOutline,
  matKeyboardArrowLeftOutline,
  matPersonSharp,
  matGroupSharp,
  matDragIndicatorSharp,
  matExpandSharp,
  octSidebarCollapse,
  octSidebarExpand,
  matReviewsOutline,
  matRequestPageOutline,
  matHandshakeOutline,
  matReceiptLongOutline,
};

@NgModule({
  imports: [NgIconsModule.withIcons(ICONS)],
  exports: [NgIconsModule],
})
export class IconsModule {}
