import { Component, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogService } from '../../../../../core/services/dialog.service';
import { SimpleDocPickerComponent } from '../../../simple-doc-picker/simple-doc-picker.component';
import { AssistantChatService } from '../../services/assistant-chat.service';

@UntilDestroy()
@Component({
  selector: 'app-chat-thread',
  templateUrl: './chat-thread.component.html',
  styleUrls: ['./chat-thread.component.scss'],
})
export class ChatThreadComponent {
  
  dialogService = inject(DialogService);

  constructor() {}

  /**
   * Estados de carga del asistente
   */
  get loadingStates() {
    return AssistantChatService.loadingStates;
  }

  /**
   * Abre el modal del selector de documentos simple y obtiene los ids de los archivos seleccionados
   */
  viewDocs() {
    this.dialogService.openDialog(SimpleDocPickerComponent, {
      data: {
        prompt: 'Selecciona archivos (.PDF) para agregar a la conversación',
        extensions: ['.pdf']
      }
    }).subscribe((docs: any) => {
      if (docs) {
        AssistantChatService.addDocs(docs);
      }
    })
  }
}
