<div
	class="z-[100] absolute shadow-lg overflow-hidden max-h-[calc(100svh-4rem)] sm:max-h-[calc(100svh-6rem)] transition-all"
	[ngClass]="{
		'w-full h-full right-0 bottom-0 rounded-none sm:border sm:border-slate-200 sm:right-8 sm:bottom-8 sm:rounded-xl sm:h-[80vh] sm:w-[70svw] xl:w-[60svw] 2xl:w-[55svw]': !this.componentStates.close,
		'!w-0 !h-0 !right-8 !bottom-8 !rounded-full !border !border-slate-200': this.componentStates.close,
		'sm:!w-full sm:!h-full sm:!right-0 sm:!bottom-0 !rounded-none !border-none !max-h-[calc(100svh-4rem)]': this.componentStates.maximize
	}"
>
	<div class="relative w-full h-full">
		<div
			(click)="open()"
			class="absolute w-full h-full top-0 left-0 bg-white z-[1] transition-all flex items-center justify-center cursor-pointer select-none"
			[ngClass]="this.componentStates.close ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'"
		>
			<img class="w-10 h-10 rounded-full" src="/assets/images/isotipo.png" alt="MiDespacho Chat Logo"/>
		</div>
		<div class="absolute w-full h-full top-0 left-0">
			<div class="relative flex h-full bg-slate-100">

				<!-- Messages sidebar -->
				<div class="absolute top-0 bottom-0 left-0 h-full w-full sm:w-auto sm:static sm:top-auto sm:bottom-auto -mr-px transition-all" [ngClass]="{ '-translate-x-full': !componentStates.threadsMenu }">
					<div class="sticky bg-white overflow-hidden shrink-0 border-r border-slate-200 sm:w-72 xl:w-80 h-full">

						<!-- #Marketing group -->
						<div class="h-full flex flex-col">

							<!-- Header -->
							<div class="sticky top-0 z-10">
								<div class="flex items-center bg-white border-b border-slate-200 px-5 h-16">
									<div class="w-full flex items-center justify-between">

										<!-- Nombre Asistente -->
										<div class="relative">
											<button class="grow flex items-center truncate">
												<img class="w-8 h-8 rounded-full mr-2" src="https://dev.midespacho.cloud/assets/images/isotipo.png" width="32" height="32" alt="MiDespacho"/>
												<div class="truncate">
													<span class="font-semibold text-slate-800">Amparo IA</span>
												</div>
											</button>
										</div>

										<div class="flex">

											<!-- botón de información -->
											<button id="assistant-tour-button" (click)="showInfoContent()" class="size-8 grid place-content-center rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Ver Información'">
												<i class="fa-duotone fa-info-circle opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
											</button>

											<!-- Ocultar sidebar de conversaciones -->
											<div class="flex">
												<button (click)="componentStates.threadsMenu = !componentStates.threadsMenu" class="w-8 h-8 cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Ocultar Conversaciones'">
													<i class="fa-duotone fa-arrow-left-from-line hover:opacity-100" style="font-size: 1rem"></i>
												</button>
											</div>

										</div>
									</div>
								</div>
							</div>

							<app-chat-threads></app-chat-threads>

						</div>
					</div>
				</div>

				<!-- Messages body -->
				<div class="absolute top-0 bottom-0 right-0 h-full w-full lg:w-[calc(100%-18rem)] xl:w-[calc(100%-20rem)] flex flex-col overflow-hidden transition-all" [ngClass]="{ '!w-full': !componentStates.threadsMenu, 'translate-x-full sm:translate-x-72 lg:translate-x-0': componentStates.threadsMenu }">
					
					<!-- Header -->
					<div>
						<div class="flex items-center justify-between bg-slate-50 border-b border-slate-200 px-4 sm:px-6 md:px-5 h-16">
							
							<!-- Close button -->
							<button *ngIf="!componentStates.threadsMenu" (click)="componentStates.threadsMenu = !componentStates.threadsMenu" class="w-8 h-8 min-w-[2rem] min-h-[2rem] mr-2 cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Mostrar Conversaciones'">
								<i class="fa-duotone fa-arrow-right-from-line opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
							</button>

							<p class="min-w-0 max-w-full font-semibold text-slate-800 inline-block truncate mr-auto" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="threadName">
								{{ threadName }}
							</p>

							<!-- Buttons on the right side -->
							<div class="flex">

								<!-- Thread docs -->
								<button id="assistant-document-list" *ngIf="loadingStates?.deletingDocuments?.value || loadingStates?.addingDocuments?.value || documents[0] || tutorialService.isTutorialActive('assistant-tour')" [menuTriggerFor]="threadDocs" class="grid w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Documentos de la Conversación'">
									<i *ngIf="!loadingStates?.deletingDocuments?.value && !loadingStates?.addingDocuments?.value" class="fa-duotone fa-file-lines opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
									<i *ngIf="loadingStates?.deletingDocuments?.value || loadingStates?.addingDocuments?.value" class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin" style="font-size: 1rem"></i>
								</button>
								<ng-template #threadDocs>
									<ul *ngIf="!loadingStates?.deletingDocuments?.value && !loadingStates?.addingDocuments?.value" class="uisr-dropdown-menu animate__animated animate__fadeIn animate__faster max-h-72 overflow-y-auto" cdkMenu>
										<li *ngFor="let doc of documents; let i = index;" cdkMenuItem class="max-w-full xs:max-w-72">
											<a role="button" class="app-menu-option justify-between">
												<span class="truncate">
													{{ doc.name }}
												</span>
												<button (click)="removeDoc(doc.idActivityFile)" class="w-8 h-8 min-w-8 min-h-8 cursor-pointer grid place-content-center p-1 rounded ml-2 text-rose-600 hover:text-red-600 " ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Eliminar de la Conversación'">
													<i class="fa-solid fa-xmark opacity-75 hover:opacity-100" style="font-size: 1rem;"></i>
												</button>
											</a>
										</li>
									</ul>
								</ng-template>

								<!-- IA Model -->
								<button id="assistant-model-selector" [menuTriggerFor]="modelsList" class="grid w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Modelo de Inteligencia Artificial'">
									<i *ngIf="!loadingStates?.loadingModels?.value" class="fa-duotone fa-microchip-ai opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
									<i *ngIf="loadingStates?.loadingModels?.value" class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin" style="font-size: 1rem"></i>
								</button>
								<ng-template #modelsList>
									<ul *ngIf="!loadingStates?.loadingModels?.value" class="uisr-dropdown-menu animate__animated animate__fadeIn animate__faster max-h-72 overflow-y-auto" cdkMenu>
										<li *ngFor="let iModel of models; let i = index;" cdkMenuItem class="max-w-full xs:max-w-72">
											<a role="button" [ngClass]="{ 'text-slate-800' : model != iModel.model_id }" class="app-menu-option justify-between group" (click)="setModel(iModel.model_id)">
												<div class="flex flex-col min-w-0 items-start w-full">
													<div class="flex items-center w-full">
														<button [ngClass]="{ '!opacity-100' : model == iModel.model_id }" class="opacity-25 group-hover:opacity-100 w-8 h-8 min-w-8 min-h-8 cursor-pointer grid place-content-center p-1 rounded mr-2">
															<i class="fa-solid fa-circle-check opacity-75 hover:opacity-100" style="font-size: 1rem;"></i>
														</button>
														<span class="truncate">
															{{ iModel.name }}
														</span>
													</div>
													<div class="text-xs text-slate-600">
														{{ iModel.description }}
													</div>
												</div>
											</a>
										</li>
									</ul>
								</ng-template>
							
								<!-- Minimize button -->
								<button *ngIf="this.componentStates.maximize" (click)="minimize()" class="hidden sm:grid w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Minimizar'">
									<i class="fa-duotone fa-arrows-minimize opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
								</button>

								<!-- Maximize button -->
								<button *ngIf="!this.componentStates.maximize" (click)="maximize()" class="hidden sm:grid w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Maximizar'">
									<i class="fa-duotone fa-arrows-maximize opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
								</button>

								<!-- botón de ayuda -->
								<button id="assistant-tour-button" (click)="startTour()" class="size-8 grid place-content-center rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Ver Ayuda'">
									<i class="fa-duotone fa-question-circle opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
								</button>

								<!-- Close button -->
								<button (click)="close()" class="w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Cerrar'">
									<i class="fa-duotone fa-xmark opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
								</button>

							</div>
						</div>
					</div>

					<!-- Thread -->
					<app-chat-thread></app-chat-thread>

				</div>
			</div>
		</div>
	</div>
</div>