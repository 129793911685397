import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { environment } from 'src/environments/environment';
import { ASSISTANT_STEPS } from '../../constants/assistant-steps.steps';
import { UserDataLocalStorage } from '../../reducer/user-data/user-data.actions';
import { AppTutorialService } from '../../services/tutorial.service';
import { UisrAuthService } from '../../services/uisr-auth.service';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
})
export class MainLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild(SidebarComponent, { static: true })
  sidebarComponent!: SidebarComponent;
  lastScrollTop: any = 0;
  
  constructor(
    private store: Store,
    private renderer: Renderer2,
    private authService: UisrAuthService,
    private tutorialService: AppTutorialService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new UserDataLocalStorage());
    this.authService.startValidationLoop();
    this.authService.updatePermissions();
    this.renderer.addClass(this.document.body, 'inner-app');
  }

  ngAfterViewInit(): void {

  }

  openSidebar() {
    this.sidebarComponent.openOrCloseSidebar();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'inner-app');
  }
}
