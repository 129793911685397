<div *appValidateAccess="menuItem.permission" class="my-[0.3rem] border-l-3 border-transparent border-r-3"
    [ngClass]="{'!border-l-primary-100 bg-mirage' : showActiveStyle() && !sidebarExpanded}">
    <a ngxTippy [tippyProps]="{allowHTML: true}"
        [routerLink]="menuItem.disabled || !sidebarExpanded  ? null : menuItem.url"
        [ngxTippy]="hasAllItemsDisabled(menuItem) ? 'Este contenido estará disponible <br> próximamente' : null"
        role="button"
        class="truncate py-[0.325rem] flex items-center justify-center sidebar-expanded:justify-between px-3 border-l-3 border-transparent w-full"
        [ngClass]="{'!border-primary-100 bg-mirage': getParentStatus()}">
        <button [ngClass]="{'justify-center': !sidebarExpanded}" class="flex flex-grow items-center gap-x-2"
            (click)="sidebarExpanded ? navigateFromParent(menuItem) : sidebarExpandRequest.emit(true)">
            <div class="w-6 h-6 grid place-content-center">
                <i class="fa-duotone" [ngClass]="menuItem.icon"
                    [ngStyle]="showActiveStyle() ? activeIcon : inactiveIcon" style="font-size: 1.5rem;"></i>
            </div>
            <span [ngClass]="{'hidden': !sidebarExpanded}"
                class="text-[0.95rem] leading-none flex items-center font-medium opacity-0 sidebar-expanded:opacity-100 duration-150 text-slate-200">{{
                menuItem.label | translate | titlecase }}</span>
        </button>
    </a>
    <div class="hidden sidebar-expanded:block" *ngIf="menuItem.children && menuItem.children[0]">
        <ul class="overflow-hidden" [ngClass]="{'hidden': !menuItem.status}">
            <ng-container *ngFor="let menuChild of menuItem.children;let i = index; trackBy:trackByFn">
                <ng-container *appValidateAccess="menuChild.permission">
                    <ng-container *ngIf="userData?.idWorkspace || userData?.id_workspace_member || menuChild.public">
                        <li *ngIf="menuChild.active" [ngClass]="{'opacity-50': menuChild.disabled}" ngxTippy [tippyProps]="{allowHTML: true}" [ngxTippy]="menuChild.tooltip ? menuChild.tooltip : null" role="button">
                            <a role="button" [routerLink]="menuChild.disabled ? null : menuChild.url" class="truncate py-[0.325rem] flex justify-between items-center pl-11 border-l-3 border-transparent opacity-0 sidebar-expanded:opacity-100 nightwind-prevent text-slate-400"
                                (mouseup)="trackChildClick(menuChild)"
                                appStringConditionedStyle
                                [conditionalClasses]="['!border-primary-100', 'bg-mirage', 'hover:text-slate-400']"
                                [stringToMatch]="menuChild.url">
                                <div class="nightwind-prevent h-6 text-[0.95rem] flex items-end font-medium opacity-0 sidebar-expanded:opacity-100 duration-150">{{ menuChild.label | translate }}</div>
                                <div *ngIf="menuChild.hasUpdate" class="flex flex-shrink-0 mr-2">
                                    <span class="flex items-center justify-center text-[0.7rem] font-medium bg-primary-100 text-pearl-100 dark:text-pearl-100 dark:bg-primary-100 px-1 rounded">Nuevo</span>
                                </div>
                            </a>
                        </li>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</div>