import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, distinctUntilKeyChanged, filter, Subject, takeUntil } from 'rxjs';
import { ALERT_DEFAULTS } from 'src/app/core/constants/alert-defaults.constants';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ChatThreadsComponent } from '../components/chat-threads/chat-threads.component';
import { ChatWindowComponent } from '../components/chat-window/chat-window.component';
import { v4 as uuidv4 } from 'uuid';
import { MarkdownService } from 'ngx-markdown';
import { DateTime } from 'luxon';
import Typed from 'typed.js';
import { select, Store } from '@ngrx/store';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AssistantChatService {

  static readonly betaWorkspaces: number[] = environment.assistantAccess;
  static readonly production: boolean = environment.production;
  static readonly resources = RESOURCES;
  static readonly hostUrl: string = ''; // Ruta host para renderizar el componente al 100% de la pantalla

  static showInfo: boolean = true;

  static models: any[] = [];

  static userData?: UserData;
  static instance: any;
  static apiService: any;
  static markdownService: any;
  static unsubscribe = new Subject<void>();

  static threadsComponent: ChatThreadsComponent;
  static chatWindowComponent: ChatWindowComponent;

  static totalThreads: number | null = null;
  static totalMessages: number | null = null;
  static threadsPage: number = 1;
  static messagesPage: number = 1;

  static thread: any = null;
  static model: string = 'gpt-4o';
  static threads: any[] = [];
  static documents: any[] = [];
  static messages: any[] = [];

  static componentStates: any = {
    threadsMenu: true,    // Visible el listado de conversaciones
    maximize: false,      // Esta maximixada la ventana del chat
    close: true,          // Esta cerrada la ventana del chat
    onHostRoute: false    // Si la ruta es la de la ventana del chat
  };

  static loadingStates = {
    loadingModels: new BehaviorSubject<boolean>(false),         // Cargando los modelos llm
    loadingThreads: new BehaviorSubject<boolean>(false),        // Cargando las conversaciones
    creatingThread: new BehaviorSubject<boolean>(false),        // Creando una nueva conversacion 
    loadingThread: new BehaviorSubject<boolean>(false),         // Cargando una conversacion (unsubscribe)
    loadingThreadMessages: new BehaviorSubject<boolean>(false), // Cargando los mensajes de una conversacion (unsubscribe)
    sendingMessage: new BehaviorSubject<boolean>(false),        // Enviando un nuevo mensaje (unsubscribe)
    addingDocuments: new BehaviorSubject<boolean>(false),       // Cargando un nuevo documento (unsubscribe)
    deletingDocuments: new BehaviorSubject<boolean>(false),     // Eliminando un documento (unsubscribe)
  };

  constructor(
    private store: Store,
    private router: Router,
    private apiService: UisrApiServiceV2,
    private markdownService: MarkdownService
  ) {

    AssistantChatService.instance = this;
    AssistantChatService.apiService = apiService;
    AssistantChatService.markdownService = markdownService;

    // Suscribirse a los datos del usuario
    this.store
      .pipe(
        select(UserDataFull),
        distinctUntilKeyChanged('id_users'),
        untilDestroyed(this)
      )
      .subscribe(async (data) => {
        AssistantChatService.userData = data;
    });

    // Subscribirse a eventos de las rutas
    this.router.events
      .subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          AssistantChatService.onDemandRouterStates(event.url)
        }

        if (event instanceof NavigationEnd) {
          AssistantChatService.onDemandRouterStates(event.urlAfterRedirects)
        }
    });
  }

  ///////////////////////////////////////////////////
  ////////// MISC

  /**
   * Valida si el asistente esta disponible para el usuario
   * Siempre disponible para LOCAL, DEV y QA
   */
  static assistantAvailable(): boolean {
    return AssistantChatService.production ? AssistantChatService.betaWorkspaces.includes(AssistantChatService.userData?.idWorkspace || -1) : true;
  }

  /**
   * Observar eventos de las rutas
   */
  static onDemandRouterStates(route: string) {
    if(route != AssistantChatService.hostUrl){
      AssistantChatService.componentStates.onHostRoute = false;
      AssistantChatService.componentStates.maximize = false;
    } else {
      AssistantChatService.componentStates.onHostRoute = true;
      AssistantChatService.componentStates.maximize = true;
      AssistantChatService.componentStates.close = false;
    }
  }

  ///////////////////////////////////////////////////
  ////////// Estados del UI

  /**
   * Maximizar chat
   */
  static maximize() {
    AssistantChatService.componentStates.maximize = true;
  }

  /**
   * Minimizar chat
   */
  static minimize() {
    AssistantChatService.componentStates.maximize = false;
  }

  /**
   * Abrir chat
   */
  static open() {
    AssistantChatService.componentStates.close = false;
    AssistantChatService.showInfoContent();
  }

  /**
   * Cerrar chat
   */
  static close() {
    AssistantChatService.minimize();
    AssistantChatService.componentStates.close = true;
  }

  /**
   * Apertura/cierre del asistente
   */
  static toggle() {
    if(AssistantChatService.componentStates.close) {
      AssistantChatService.open();
    } else {
      AssistantChatService.close();
    }
  }

  /**
   * Mostrar informacion del asistente
   */
  static showInfoContent() {
    AssistantChatService.deactivateThread();
    AssistantChatService.showInfo = true;
  }

  /**
   * Ocultar informacion del asistente
   */
  static hideInfoContent() {
    AssistantChatService.showInfo = false;
  }

  ///////////////////////////////////////////////////
  ////////// Estados del UI

  // (Funcional) ✅
  /**
   * Obtener catalogo de modelos
   */
  static fetchModels() {
    AssistantChatService.apiService
      .get(AssistantChatService.resources.assistantModels, {})
      .pipe(
        untilDestroyed(AssistantChatService.instance),
        loadingState(AssistantChatService.loadingStates.loadingModels)
      )
      .subscribe({
        next: (res: any) => {
          if(res.success) {
            AssistantChatService.models = res.data;
          }
        },
        error: (error: any) => {
          // Implementar
        }
      });
  }

  // (Funcional) ✅
  /**
   * Estabkecer modelo
   */
  static setModel(modelId: string) {
    // Si hay una conversacion activa
    if(AssistantChatService.thread?.threadId) {
      AssistantChatService.setThreadModel(modelId);
    } else {
      AssistantChatService.setLocalModel(modelId);
    }
  }

  // (Funcional) ✅
  /**
   * Estabkecer modelo para la conversacion
   */
  static setThreadModel(modelId: string) {

    let threadId = AssistantChatService.thread?.threadId;

    AssistantChatService.apiService
      .patch(`${AssistantChatService.resources.assistantThread}/${threadId}`, {
        model_id: modelId
      })
      .pipe(
        untilDestroyed(AssistantChatService.instance),
        takeUntil(AssistantChatService.unsubscribe),
        loadingState(AssistantChatService.loadingStates.loadingModels)
      )
      .subscribe({
        next: (res: any) => {
          if(res.success) {
            AssistantChatService.model = modelId;
          }
        },
        error: (error: any) => {
          // Implementar
        }
      });
  }

  // (Funcional) ✅
  /**
   * Estabkecer modelo para la nueva conversacion
   */
  static setLocalModel(modelId: string) {
    AssistantChatService.model = modelId ? modelId : 'gpt-4o';
  }

  ///////////////////////////////////////////////////
  ////////// Chat

  // (Funcional) ✅
  /**
   * Enviar mensaje a la conversacion o crear una nueva
   */
  static sendMessage(message: string) {
    // Si hay una conversacion activa
    if(AssistantChatService.thread?.threadId) {
      AssistantChatService.sendThreadMessage(message);
    } else {
      AssistantChatService.createThread(message);
    }
  }

  // (Funcional) ✅
  /**
   * Enviar mensaje a la conversacion
   */
  static sendThreadMessage(message: string) {

    let threadId = AssistantChatService.thread?.threadId;
    let newMessageId = uuidv4();

    // Agregar mensaje de usuario
    AssistantChatService.messages.push({
      id: newMessageId,
      role: 'user',
      loading: true,
      content: AssistantChatService.markdownService.parse(message),
      created_at: DateTime.now().toUTC().toISO(),
    });

    // Colocar scroll hasta el nuevo mensaje
    AssistantChatService.scrollToBottom();

    // Enviar mensaje a la conversacion
    AssistantChatService.apiService
      .post(AssistantChatService.resources.assistantThread, {
        user_prompt: message,
        thread_id: threadId
      })
      .pipe(
        untilDestroyed(AssistantChatService.instance),
        takeUntil(AssistantChatService.unsubscribe),
        loadingState(AssistantChatService.loadingStates.sendingMessage)
      )
      .subscribe({
        next: (res: any) => {
          if(res.success) {

            // Mensaje original
            let message = AssistantChatService.messages.find((m) => m.id == newMessageId);

            // Reiniciar loading y asignar id
            message.loading = false;
            message.id = res.data.completion.last_message_ids.user;

            // Agregar mensaje de respuesta del asistente
            AssistantChatService.pushAssistantMessages([{
              id: res.data.completion.last_message_ids.assistant,
              role: 'assistant',
              loading: false,
              content: AssistantChatService.markdownService.parse(res.data.completion.assistant_response),
              created_at: DateTime.now().toUTC().toISO(),
            }], threadId);

            // Colocar scroll hasta el nuevo mensaje
            AssistantChatService.scrollToBottom();

          }
        },
        error: (error: any) => {
          // Implementar
        }
      });
  }

  // (Funcional) ✅
  /**
   * Crear nueva conversacion
   */
  static createThread(message: string) {

    let newMessageId = uuidv4();

    // Agregar mensaje de usuario
    AssistantChatService.messages.push({
      id: newMessageId,
      role: 'user',
      loading: true,
      content: AssistantChatService.markdownService.parse(message),
      created_at: DateTime.now().toUTC().toISO(),
    });

    // Colocar scroll hasta el nuevo mensaje
    AssistantChatService.scrollToBottom();

    // Datos de la peticion
    let serviceData: any = {
      user_prompt: message
    }

    // Si hay documentos preseleccionados
    if(AssistantChatService.documents[0]) {
      serviceData.files = AssistantChatService.documents;
    }

    // Si hay modelo de asistente preseleccionado
    if(AssistantChatService.model) {
      serviceData.model_id = AssistantChatService.model;
    }

		// Crear nueva conversacion
		AssistantChatService.apiService
			.post(AssistantChatService.resources.assistantThread, serviceData)
			.pipe(
				untilDestroyed(AssistantChatService.instance),
				loadingState(AssistantChatService.loadingStates.creatingThread),
        loadingState(AssistantChatService.loadingStates.sendingMessage),
			)
			.subscribe({
				next: (res: any) => {
          if(res.success) {

            const thread = {
              name: res.data.completion.thread_name,
              threadId: res.data.completion.thread_id
            }

            // Ejecutar animacion de creacion de la conversacion
            AssistantChatService.pushNewThread(thread);

            // Si no hay conversacion activa (abrio una mientras se creaba la nueva)
            if(!AssistantChatService.thread) {

              // Establecer nueva conversacion como la actual
              AssistantChatService.thread = thread;
              
              // Mensaje original
              let message = AssistantChatService.messages.find((m) => m.id == newMessageId);

              if(message){

                // Reiniciar loading y asignar id
                message.loading = false;
                message.id = res.data.completion.last_message_ids.user;

              }

              // Agregar mensaje de respuesta del asistente
              AssistantChatService.pushAssistantMessages([{
                id: res.data.completion.last_message_ids.assistant,
                role: 'assistant',
                loading: false,
                content: AssistantChatService.markdownService.parse(res.data.completion.assistant_response),
                created_at: DateTime.now().toISO(),
              }], res.data.completion.thread_id);

              // Colocar scroll hasta el nuevo mensaje
              AssistantChatService.scrollToBottom();

            }

          }
				},
				error: (error: any) => {
          // Implementar
				}
			});
  }

  // (Funcional) ✅
  /**
   * Obtener los mensajes de una conversacion
   */
  static fetchThreadMessages(threadId: any) {

    let firstLoad = AssistantChatService.messagesPage > 1 ? false : true;

    AssistantChatService.apiService
      .get(`${AssistantChatService.resources.assistantThread}/${threadId}/messages`, {
        pageSize: 10,
        pageNumber: AssistantChatService.messagesPage,
      })
      .pipe(
        untilDestroyed(AssistantChatService.instance),
        takeUntil(AssistantChatService.unsubscribe),
        loadingState(firstLoad ? AssistantChatService.loadingStates.loadingThread : AssistantChatService.loadingStates.loadingThreadMessages)
      )
      .subscribe({
        next: (res: any) => {
          if(res.success) {

            // Total de mensajes en la conversación
            AssistantChatService.totalMessages = res.total;

            // Agregar mensajes al array
            res.data.forEach((message: any) => {
              AssistantChatService.messages.unshift({
                id: message.message_id,
                role: message.role,
                loading: false,
                error: false,
                content: AssistantChatService.markdownService.parse(message.content),
                created_at: message.timestamp,
              });
            });
            
            // Colocar scroll hasta el nuevo mensaje
            if(firstLoad){
              AssistantChatService.scrollToBottom();
            }

            // Comprobar carga automatica de la siguiente pagina
            setTimeout(() => {
              AssistantChatService.chatWindowComponent.checkThreadMessagesScroll();
            }, 0);

          }
        },
				error: (error: any) => {
          // Implementar
				}
      });
  }

  // (Funcional) ✅
  /**
   * Obtener la siguiente pagina de mensajes
   */
  static fetchNextMessages() {

    // Si esta cargando actualmente se ignora
    if (AssistantChatService.loadingStates.loadingThread.value || AssistantChatService.loadingStates.loadingThreadMessages.value) {
      return;
    }

    // Si ya se tiene el total de mensajes se ignora
    if (AssistantChatService.messages.length >= (AssistantChatService.totalMessages || 0)) {
      return;
    }

    // Incrementar el numero de pagina
    AssistantChatService.messagesPage = AssistantChatService.messagesPage + 1;

    // Obtener Mensajes
    AssistantChatService.fetchThreadMessages(AssistantChatService.thread?.threadId);
    
  }

  // (REVISADO)
  /**
   * Reintentar enviar un mensaje fallido
   */
  static retryMessage(messageId: any) {

    let index = -1;
    let message = AssistantChatService.messages.find((m, i) => {
      if (m.id == messageId) {
        index = i;
      }

      return m.id == messageId;
    });

    // remover mensaje erroneo
    AssistantChatService.messages.splice(index, 1);

    // Reintentar mensaje
    AssistantChatService.sendMessage(message.content);

  }

  // (Funcional) ✅
  /**
   * Colocar scroll hasta el principio
   */
  static scrollToBottom() {
    setTimeout(() => {
      AssistantChatService.chatWindowComponent.messagesWrapper.nativeElement.scrollTop = 9e9;
    }, 0);
  }

  // (Funcional) ✅
  /**
   * Ejecutar animacion de reproduccion de escritura del asistente
   */
  static async pushAssistantMessages(messages: any[], threadId: string) {

    // Esta promesa se completa al finalizar la animacion de escritura
    const typedAnimation = (
      typedId: string,
      content: string,
      messageId: string
    ) => {
      new Promise<void>((r) => {
        setTimeout(() => {
          // https://github.com/mattboldt/typed.js/
          const typed = new Typed(`#${typedId}`, {
            strings: [content],
            showCursor: false,
            typeSpeed: 1,
            autoInsertCss: false,
            onComplete: (self) => {
              // Remover typedId para preservar estructura original del mensaje
              // y prevenir error del maquetado al ejecutar el metodo destroy();
              const message = AssistantChatService.messages.find(
                (message: any) => message.id == messageId
              );

              if(message) {
                delete message.typedId;
              }

              self.destroy();
              r();
            },
          });
        }, 0);
      });
    };

    // Iterar sobre cada mensaje nuevo del asistente
    // 1. Insertar mensaje
    // 2. Reproducir animacion de escritura
    // 3. Esperar que se complete animacion
    // 4. (si existe otro mensaje) repetir
    for (let i = 0; i < messages.length; i++) {

      // Si la conversacion cambia en algun momento se finaliza
      if(threadId != AssistantChatService.thread?.threadId) {
        return;
      }

      const message = messages[i];
      const typedId = `typed-${uuidv4()}`;

      message.typedId = typedId;

      AssistantChatService.messages.push(message);

      await typedAnimation(typedId, message.content, message.id);

    }
  }

  ///////////////////////////////////////////////////
  ////////// Conversaciones

  // (Funcional) ✅
  /**
   * Activar una conversacion
   */
  static activateThread(threadId: any) {

    if(threadId == AssistantChatService.thread?.threadId) {
      return;
    }
    
    AssistantChatService.deactivateThread()
    AssistantChatService.thread = AssistantChatService.threads.find((t: any) => t.threadId == threadId);

    AssistantChatService.fetchThread(threadId, true, true);
    
  }

  // (Funcional) ✅
  /**
   * Desactivar una conversacion
   */
  static deactivateThread() {
    AssistantChatService.hideInfoContent();
    AssistantChatService.unsubscribe.next();
    AssistantChatService.thread = null;
    AssistantChatService.model = 'gpt-4o';
    AssistantChatService.documents = [];
    AssistantChatService.messages = [];
  }

  // (Funcional) ✅
  /**
   * Obtener una conversacion (Opcionalmente la primer pagina de mensajes y los documentos)
   */
  static fetchThread(threadId: any, messages: boolean = false, documents: boolean = false) {

    AssistantChatService.apiService
      .get(`${AssistantChatService.resources.assistantThread}/${threadId}`, {
        documents: documents,
        messages: messages,
      })
      .pipe(
        untilDestroyed(AssistantChatService.instance),
        takeUntil(AssistantChatService.unsubscribe),
        loadingState(AssistantChatService.loadingStates.loadingThread),
        loadingState(AssistantChatService.loadingStates.addingDocuments),
        loadingState(AssistantChatService.loadingStates.loadingModels)
      )
      .subscribe({
        next: (res: any) => {
          if(res.success) {

            // Establecer el modelo
            const model = AssistantChatService.models.find((model: any) => res.data.model_id == model.model_id);
            
            AssistantChatService.model = model ? res.data.model_id : 'gpt-4o';

            // Si se solicito la primer pagina de mensajes
            if(messages && res.data.messages){

              // Total de mensajes en la conversación
              AssistantChatService.totalMessages = res.data.messages.total;

              // Agregar mensajes al array
              res.data.messages.data.forEach((message: any) => {
                AssistantChatService.messages.unshift({
                  id: message.message_id,
                  role: message.role,
                  loading: false,
                  error: false,
                  content: AssistantChatService.markdownService.parse(message.content),
                  created_at: message.timestamp,
                });
              });

            }

            // Si se solicito todos los documentos
            if(documents && res.data.documents){

              let docs: any[] = [];

              res.data.documents?.forEach((doc: any) => {
                docs.push({
                  id: doc.idActivityFile,
                  idActivityFile: doc.idActivityFile,
                  name: doc.name
                });
              });

              AssistantChatService.documents =  [...new Set((AssistantChatService.documents || []).concat(docs))];

            }

            // Colocar scroll hasta el nuevo mensaje
            AssistantChatService.scrollToBottom();

            // Comprobar carga automatica de la siguiente pagina
            setTimeout(() => {
              AssistantChatService.chatWindowComponent.checkThreadMessagesScroll();
            }, 0);

          }
        },
        error: (error: any) => {
          // Implementar
        }
      });
  }

  // (Funcional) ✅
  /**
   * Obtener conversaciones del usuario
   */
  static fetchThreads() {
    AssistantChatService.apiService
      .get(AssistantChatService.resources.assistantUserThreads, {
        pageSize: 10,
        pageNumber: AssistantChatService.threadsPage,
      })
      .pipe(
        untilDestroyed(AssistantChatService.instance),
        loadingState(AssistantChatService.loadingStates.loadingThreads)
      )
      .subscribe({
        next: (res: any) => {
          if(res.success) {
            // Total de conversaciones
            AssistantChatService.totalThreads = res.total;

            // Agregar converasiones al array
            res.data.forEach((thread: any) => {

              // Si la conversacion no existe en el listado actual
              if(!AssistantChatService.threads.find((t: any) => t.threadId == thread.thread_id)) {
                AssistantChatService.threads.push({
                  threadId: thread.thread_id,
                  name: thread.thread_name,
                  created_at: thread.start_time
                });
              }
            });

            // Comprobar carga automatica de la siguiente pagina
            setTimeout(() => {
              AssistantChatService.threadsComponent?.checkThreadsScroll()
            }, 0);
          }
        },
        error: (error: any) => {
          // Implementar
        }
      });
  }

  // (Funcional) ✅
  /**
   * Obtener la siguiente pagina de conversaciones
   */
	static fetchNextThreads() {

		// Si esta cargando actualmente se ignora
		if(AssistantChatService.loadingStates.loadingThreads.value){
			return;
		}

		// Si ya se tiene el total de conversaciones se ignora
		if(AssistantChatService.threads.length >= (AssistantChatService.totalThreads || 0)){
			return;
		}

		// Incrementar el numero de pagina
    AssistantChatService.threadsPage = AssistantChatService.threadsPage + 1;

		// Obtener conversaciones
    AssistantChatService.fetchThreads();
    
	}

  // (Funcional) ✅
  /**
   * Eliminar conversacion
   */
  static deleteThread(threadId: string) {
    Swal.fire({
      ...ALERT_DEFAULTS,
      ...{
        title: 'Confirmación Requerida',
        icon: 'question',
        text: '¿Deseas eliminar esta conversacion?',
        showCancelButton: true,
        showConfirmButton: true
      },
    }).then((res: SweetAlertResult<any>) => {
      if (res.isConfirmed) {
        AssistantChatService.apiService.delete(`${AssistantChatService.resources.assistantThread}/${threadId}`,{})
        .pipe(
          untilDestroyed(AssistantChatService.instance),
          loadingState(AssistantChatService.loadingStates.loadingThreads)
        )
        .subscribe({
          next: (res: any) => {
            if(res.success) {
              if(AssistantChatService.thread?.threadId == threadId) {
                AssistantChatService.deactivateThread();
              }
  
              const deleted = AssistantChatService.threads.findIndex((thread: any) => thread.threadId == threadId);
              AssistantChatService.threads.splice(deleted, 1);
              AssistantChatService.totalThreads = res.total;
            }
          },
          error: (error: any) => {
            // Implementar
          }
        });
      }
    });
  }

  // (Funcional) ✅
  /**
   * Reproducir animacion de la nueva conversacion
   */
  static async pushNewThread(data: any) {

    // Esta promesa se completa al finalizar la animacion de escritura
    const typedAnimation = (
      typedId: string,
      content: string,
      threadId: string
    ) => {
      new Promise<void>((r) => {
        setTimeout(() => {
          // https://github.com/mattboldt/typed.js/
          const typed = new Typed(`#${typedId}`, {
            strings: [content],
            showCursor: false,
            typeSpeed: 30,
            autoInsertCss: false,
            onComplete: (self) => {
              // Remover typedId para preservar estructura original del elemento
              // y prevenir error del maquetado al ejecutar el metodo destroy();
              if (AssistantChatService.threads[0]) {
                const thread = AssistantChatService.threads.find(
                  (thread: any) => thread.threadId == threadId
                );

                if(thread) {
                  delete thread.typedId;
                }
              }
          
              self.destroy();
              r();
            },
          });
        }, 300); // <-- Este es el tiempo adecuando segunn la animacion "-intro-x" del elemento en el maquetado
      });
    }
  
    // Id de la animacion de escritura
    const typedId = `typed-${uuidv4()}`;
  
    // Agregar nuevo thread a la posicion 0
    AssistantChatService.threads.unshift({
      threadId: data.threadId,
      name: data.name,
      created_at: DateTime.now().toISO(),
      typedId: typedId
    });
  
    // Establecer total de conversaciones
    AssistantChatService.totalThreads = (AssistantChatService.totalThreads || 0) + 1;

    // Animacion de escritura
    typedAnimation(
      typedId,
      data.name,
      data.threadId
    );
  }

  ///////////////////////////////////////////////////
  ////////// Documentos

  /**
   * Obtener los documentos de una conversacion
   */
  static fetchThreadDocs(threadId: string) {

    AssistantChatService.apiService
      .get(`${AssistantChatService.resources.assistantThread}/${threadId}/documents`, {})
      .pipe(
        untilDestroyed(AssistantChatService.instance),
        takeUntil(AssistantChatService.unsubscribe),
        loadingState(AssistantChatService.loadingStates.addingDocuments)
      )
      .subscribe({
        next: (res: any) => {
          if(res.success) {

            let docs: any[] = [];

            res.data?.forEach((doc: any) => {
              docs.push({
                id: doc.idActivityFile,
                idActivityFile: doc.idActivityFile,
                name: doc.name
              });
            });
  
            AssistantChatService.documents =  [...new Set(docs)];

          }
        },
				error: (error: any) => {
          // Implementar
				}
      });
  }

  // (Funcional) ✅
  /**
   * Agregar documentos
   */
  static addDocs(docs: any[]) {

    let actualDocs = AssistantChatService.documents || [];
    let docsIds: any[] = [];

    docs.forEach((doc: any) => {
      // Si el documento no existe
      if(!actualDocs.find((d: any) => d.idActivityFile == doc.idActivityFile)) {
        docsIds.push({
          id: doc.idActivityFile,
          idActivityFile: doc.idActivityFile,
          name: doc.name
        });
      }
    });

    // Si no se agrego ningun nuevo documento, finalizar
    if(!docsIds[0]) return;

    // Si hay una conversacion activa
    if(AssistantChatService.thread?.threadId) {
      AssistantChatService.addThreadDocs(AssistantChatService.thread?.threadId, docsIds);
    } else {
      AssistantChatService.addLocalDocs(docsIds);
    }
  }

  // (Funcional) ✅
  /**
   * Agregar documentos a una conversacion
   */
  static addThreadDocs(threadId: string, docs: any[]) {
    AssistantChatService.apiService
      .patch(`${AssistantChatService.resources.assistantThread}/${threadId}`, {
        docs: docs
      })
      .pipe(
        untilDestroyed(AssistantChatService.instance),
        takeUntil(AssistantChatService.unsubscribe),
        loadingState(AssistantChatService.loadingStates.addingDocuments)
      )
      .subscribe({
        next: (res: any) => {
          if(res.success) {
            AssistantChatService.documents =  [...new Set((AssistantChatService.documents || []).concat(docs))];
          }
        },
        error: (error: any) => {
          // Implementar
        }
      });
  }

  // (Funcional) ✅
  /**
   * Agregar documentos a una nueva conversacion
   */
  static addLocalDocs(docs: any[]) {
    AssistantChatService.apiService
      .post(`${AssistantChatService.resources.assistantVectorize}`, {
        docs: docs
      })
      .pipe(
        untilDestroyed(AssistantChatService.instance),
        takeUntil(AssistantChatService.unsubscribe),
        loadingState(AssistantChatService.loadingStates.addingDocuments)
      )
      .subscribe({
        next: (res: any) => {
          if(res.success) {
            AssistantChatService.documents =  [...new Set((AssistantChatService.documents || []).concat(docs))];
          }
        },
        error: (error: any) => {
          // Implementar
        }
      });
  }

  // (Funcional) ✅
  /**
   * Eliminar un documento
   */
  static removeDoc(docId: string) {
    // Si hay una conversacion activa
    if(AssistantChatService.thread?.threadId) {
      AssistantChatService.removeThreadDoc(AssistantChatService.thread?.threadId, docId);
    } else {
      AssistantChatService.removeLocalDoc(docId);
    }
  }

  // (Funcional) ✅
  /**
   * Eliminar un documento de una conversacion
   */
  static removeThreadDoc(threadId: string, docId: string) {
    Swal.fire({
      ...ALERT_DEFAULTS,
      ...{
        title: 'Confirmación Requerida',
        icon: 'question',
        text: '¿Deseas eliminar el documento de la conversacion?',
        showCancelButton: true,
        showConfirmButton: true
      },
    }).then((res: SweetAlertResult<any>) => {
      if (res.isConfirmed) {
        AssistantChatService.apiService.delete(`${AssistantChatService.resources.assistantThread}/${threadId}/${docId}`,{})
        .pipe(
          untilDestroyed(AssistantChatService.instance),
          takeUntil(AssistantChatService.unsubscribe),
          loadingState(AssistantChatService.loadingStates.deletingDocuments)
        )
        .subscribe({
          next: (res: any) => {
            if(res.success) {
              AssistantChatService.documents = AssistantChatService.documents?.filter((doc: any) => doc.idActivityFile != docId) || null;
            }
          },
          error: (error: any) => {
            // Implementar
          }
        });
      }
    });
  }

  // (Funcional) ✅
  /**
   * Eliminar documentos vectorizados para la nueva conversacion
   */
  static removeLocalDoc(docId: string) {
    AssistantChatService.documents = AssistantChatService.documents?.filter((doc: any) => doc.idActivityFile != docId) || null;
  }

}
