import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { MarkdownService } from 'ngx-markdown';
import { distinctUntilKeyChanged, takeUntil } from 'rxjs';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { AssistantChatService } from '../../services/assistant-chat.service';
import { AppTutorialService } from 'src/app/core/services/tutorial.service';

@UntilDestroy()
@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent {
  tutorialService = inject(AppTutorialService);

  @ViewChild('messagesWrapper') public messagesWrapper!: ElementRef;

  userData?: UserData
  infoContent = '';

  constructor(
    private store: Store,
    private markdownService: MarkdownService
  ) {

    AssistantChatService.chatWindowComponent = this;

    // Suscribirse a los datos del usuario
    this.store
    .pipe(
      select(UserDataFull),
      distinctUntilKeyChanged('id_users'),
      untilDestroyed(this)
    )
    .subscribe(async (data) => {
      this.userData = data;
    });

    this.infoContent = AssistantChatService.markdownService.parse(`
      ## Bienvenido a Amparo IA en MiDespacho
      
      Amparo IA es tu asistente legal inteligente, diseñado para ayudarte a gestionar y simplificar tu trabajo en la plataforma MiDespacho. A continuación, te presentamos las capacidades actuales de Amparo IA y las novedades que estamos preparando para mejorar tu experiencia.

      **🚀 Capacidades Actuales de Amparo IA**

      1.	**Interpretación de Documentos PDF**: Amparo IA puede responder preguntas específicas sobre el contenido de documentos en formato PDF, facilitando la obtención de información relevante sin necesidad de revisar el documento completo.

      2.	**Resumen de Documentos**: Optimiza tu tiempo con la función de resumen de documentos, permitiendo obtener las ideas clave de un texto sin necesidad de leerlo en su totalidad.

      3.	**Asistencia Legal Básica**: Amparo IA está equipado para asistirte en temas legales básicos, ofreciéndote soporte y orientación rápida sobre cuestiones rutinarias.

      4.	**Tutoriales de Plataforma**: Explora tutoriales interactivos que te mostrarán cómo aprovechar al máximo las funciones de MiDespacho, incluido el uso de Amparo IA.

      **⏰ Próximamente en Amparo IA**

      Estamos trabajando para ampliar las capacidades de Amparo IA con nuevas funciones que mejorarán aún más tu flujo de trabajo:

      1.	**Soporte para Múltiples Tipos de Documentos**: Pronto podrás trabajar con documentos en formatos como DOC, DOCX, PPTX, TXT y JSON.

      2.	**Inclusión de Bibliografía**: Amparo IA facilitará la inclusión de referencias bibliográficas en los documentos que generes.

      3.	**Módulo de Ajustes Personalizados**: Personaliza la configuración de Amparo IA según tus preferencias y necesidades específicas.

      4.	**Compartir Chats con Colaboradores**: Mejora la colaboración permitiendo compartir chats e interacciones con Amparo IA directamente con tus colaboradores.

      5.	**Selección de Modelo de IA**: Elige entre diferentes modelos de inteligencia artificial para adaptar las respuestas y funcionalidades de Amparo IA a tu estilo de trabajo.

      **🔮 A Futuro**

      Mirando hacia el futuro, planeamos integrar funcionalidades avanzadas que transformarán tu manera de trabajar:

      1.	**Gestión de Eventos y Recordatorios en el Calendario**: Amparo IA te ayudará a organizar tu agenda, configurando eventos y recordatorios directamente desde la plataforma.

      2.	**Búsquedas de Leyes y Precedentes**: Accede a una base de datos completa de leyes, sentencias, jurisprudencias y citas legales directamente a través de Amparo IA.

      Amparo IA está aquí para asistirte y hacer que tu práctica legal sea más eficiente. ¡Explora todas estas funciones y prepara tu despacho para el futuro con MiDespacho!
    `);

  }

  /**
   * Estados de carga del asistente
   */
  get loadingStates() {
    return AssistantChatService.loadingStates;
  }

  /**
   * Mensajes de la conversacion
   */
  get messages() {
    return AssistantChatService.messages;
  }

  /**
   * Documentos de la conversacion
   */
  get documents() {
    return AssistantChatService.documents;
  }

  /**
   * Conversacion
   */
  get thread() {
    return AssistantChatService.thread;
  }

  /**
   * Mostrar informacion del asistente
   */
  get showInfo() {
    return AssistantChatService.showInfo;
  }

  /**
   * Obtener los mensajes de una conversacion
   */
  get fetchNextMessages() {
    return AssistantChatService.fetchNextMessages;
  }

  /**
   * Reintentar mensaje
   */
  get retryMessage() {
    return AssistantChatService.retryMessage;
  }

  /**
   * Comprobar si el listado de conversasiones debe cargar automaticamente mas conversaciones
   */
	checkThreadMessagesScroll() {
		const container = this.messagesWrapper.nativeElement;

		if (container.scrollHeight <= container.clientHeight && !AssistantChatService.loadingStates.loadingThread.value && !AssistantChatService.loadingStates.loadingThreadMessages.value) {
      AssistantChatService.fetchNextMessages();
		}
	}

  /**
   * Calcular si el mensaje actual esta en el dia siguiente con respecto al anterior
   */
  isNewDayMessage(actualDate: string, previousDate: string) {

    // Prevenir error de consultar indice inexistente anterior
    // o error de los mensajes nuevos (salientes) que no tienen "created_at"
    let actualDateS = actualDate
      ? DateTime.fromISO(actualDate).toLocal().toSeconds()
      : DateTime.now().toSeconds();
    let previousDateS = previousDate
      ? DateTime.fromISO(previousDate).toLocal().toSeconds()
      : 1000000000;

    let aDate = DateTime.fromISO(
      DateTime.fromSeconds(actualDateS).toISODate() || ''
    );

    let pDate = DateTime.fromISO(
      DateTime.fromSeconds(previousDateS).toISODate() || ''
    );

    let sDate = aDate.setLocale('es').toFormat("cccc, dd 'de' LLLL");
    let sTime = DateTime.fromSeconds(actualDateS)
      .setLocale('en')
      .toLocaleString(DateTime.TIME_SIMPLE);

    return {
      condition: aDate > pDate,
      date: sDate,
      time: sTime,
    };
  }

  /**
   * Imagen del usuario
   */
  get profilePicture() {
    return this.userData?.selfie ? this.userData?.selfie : '/assets/images/profile-placeholder.png';
  }

}
