import { Injectable, OnDestroy, inject } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ServiceService implements OnDestroy {
  private url = environment.apiSocketUrl; // Asegúrate de que esta es la URL de tu servidor de Nest.js
  private pathSocket = environment.pathSocket;
  private socket: Socket | any;
  public toastr = inject(ToastrService);
  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  public numberCount$: BehaviorSubject<any> = new BehaviorSubject(null);
  public NotificationUpdateUser$: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  private token: string | null = '';


  constructor() {
    // Intenta conectarte al servidor de Socket.IO si el usuario está en línea
    this.token = localStorage.getItem('accessToken');

    if (navigator.onLine) {
      this.connectToServer();
    }
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

  }

  ngOnDestroy() {
    // Desconéctate del servidor de Socket.IO cuando se destruye el componente
    this.disconnectFromServer();

    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }
  handleConnectionChange = (event: Event) => {
    if (event.type === 'online') {
      this.toastr.success('Conexión establecida', 'Conexión');
      // Cuando el usuario se conecta, intenta conectarte al servidor de Socket.IO
      this.connectToServer();
    } else if (event.type === 'offline') {
      this.toastr.error('Conexión perdida', 'Conexión');
      // Cuando el usuario se desconecta, desconéctate del servidor de Socket.IO
      this.disconnectFromServer();
    }
  };
  disconnectFromServer() {
    if (this.socket) {
      this.socket.disconnect();
      this.numberCount$.next(null);
      this.message$.next('');
    }
  }

  connectToServer() {
    this.socket = io(this.url, {
      path: this.pathSocket,
      transports: ['websocket'],
      query: { token: this.token },
      extraHeaders: {
        token: "Bearer " + this.token,
      },
    });
    this.socket.on('error', (error: string) => {
      console.error(`Error: ${error}`);
    });
  }

  public sendMessage(message: any) {
    this.socket.emit('messageFront', message);
  }

  public getMessages = () => {
    this.socket.on('message', (message: any) => {
      this.message$.next(message);
    });

    return this.message$.asObservable();
  };
  public subscribeNumber() {
    this.socket.emit('numberFront', true);
  }
  public getNumberCount = () => {
    this.socket.on('numberCount', (numberCount: any) => {
      this.numberCount$.next(numberCount);
    });
    return this.numberCount$.asObservable();
  };
  public getNotificationUpdateUser = () => {
    this.socket.on('notificationUpdateUserApi', (data: any) => {
      this.NotificationUpdateUser$.next(data);
    });
    return this.NotificationUpdateUser$.asObservable();
  };
}
