import Step from 'shepherd.js/src/types/step';
import { AssistantChatService } from 'src/app/shared/components/assistant-chat/services/assistant-chat.service';

export const ASSISTANT_STEPS: Step.StepOptions[] = [
  {
    attachTo: {
      element:
        document.getElementById('assistant-button') || '#assistant-button',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('assistant-button') || false) as boolean;
    },
    title: 'Conoce a tu Asistente Amparo',
    text: `<p>Ahora tienes a tu disposición a <span class="app-name">Amparo IA</span>, tu nuevo asistente legal. ¡Estamos aquí para ayudarte!</p>
           <p>Haz clic en el botón para iniciar y descubrir cómo Amparo puede facilitar tu trabajo.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('assistant-threads') || '#assistant-threads',
    },
    showOn() {
      return (document.getElementById('assistant-button') || false) as boolean;
    },
    beforeShowPromise() {
      return new Promise((resolve) => {
        if (AssistantChatService.componentStates.close) {
          AssistantChatService.open();
        }
        setTimeout(() => {
          resolve(true);
        }, 10);
      });
    },
    title: 'Tus conversaciones',
    text: `<p>En esta sección puedes ver tus conversaciones. Podrás retomar conversaciones pasadas o continuar en otros dispositivos.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('assistant-messages-wrapper') ||
        '#assistant-messages-wrapper',
      on: 'left',
    },
    showOn() {
      return (document.getElementById('assistant-button') || false) as boolean;
    },
    title: 'Interacción sencilla',
    text: `<p>Aquí verás tus mensajes y las respuestas de Amparo IA.</p>
           <p><span class="font-medium">Tus datos son privados.</span> Tu asistente nunca usará datos para entrenar modelos. Con lo cual tus datos siempre estarán seguros y privados.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('assistant-chat-wrapper') ||
        '#assistant-chat-wrapper',
      on: 'right',
    },
    showOn() {
      return (document.getElementById('assistant-button') || false) as boolean;
    },
    title: 'Inicia una Conversación',
    text: `<p>Empieza una nueva conversación desde la ventana del asistente.</p>
           <p>Simplemente formula tu pregunta y deja que Amparo haga el resto. Actualmente, Amparo IA no guarda preferencias o memorias que persistan más allá de una conversación</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('assistant-add-docs') || '#assistant-add-docs',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('assistant-button') || false) as boolean;
    },
    title: 'Agrega Documentos',
    text: `<p>Podrás agregar PDFs que hayas cargado a tus asuntos a tu conversación, Amparo IA puede analizar los documentos y responder a preguntas como:</p>
           <ul>
            <li>¿Cuáles son las secciones más relevantes del documento?</li>
            <li>¿Cuáles son los argumentos más importantes</li>
           </ul>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('assistant-document-list') ||
        '#assistant-document-list',
      on: 'top',
    },
    showOn() {
      return (document.getElementById('assistant-button') || false) as boolean;
    },
    title: 'Documentos Agregados',
    text: `<p>Acá verás el listado de documentos que han agregado a la conversación.</p>
           <p>Los documentos se asocian a una conversación en especifico y podrás eliminarlo de ella en el momento que lo necesites.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('assistant-model-selector') ||
        '#assistant-model-selector',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('assistant-button') || false) as boolean;
    },
    title: 'Modelos de IA',
    text: `<p>Acá podrás ver los diferentes modelos de inteligencia artificial disponibles para tu asistente y elegir el que mejor se adapte a tus necesidades.</p>`,
  },
  {
    attachTo: {
      element:
        document.getElementById('assistant-tour-button') ||
        '#assistant-tour-button',
      on: 'bottom',
    },
    showOn() {
      return (document.getElementById('assistant-button') || false) as boolean;
    },
    title: 'Repite el tutorial',
    text: `<p>Si necesitas repasar estas instrucciones, puedes hacer clic en el icono de ayuda en cualquier momento para repetir este tutorial.</p>`,
  },
];
