import { ElementRef } from '@angular/core';
import Cleave from 'cleave.js';
import { FILE_EXTENSIONS } from '../constants/file-extensions.constants';
import { DateTime } from 'luxon';
export class UisrTools {
  // Cargar script / librería externa y ejecutar código onload
  static loadScript(
    id: string,
    src: string,
    onload: any,
    parentElement: null | HTMLHeadElement | undefined = null
  ): void {
    // get document if platform is only browser
    if (typeof document !== 'undefined' && !document.getElementById(id)) {
      let signInJS = document.createElement('script');
      signInJS.async = true;
      signInJS.src = src;
      signInJS.onload = onload;

      if (!parentElement) {
        parentElement = document.head;
      }

      parentElement.appendChild(signInJS);
    }
  }

  /**
   * Elimina las propiedades nulas, no definidas y vacías de un objeto
   *
   * @param {object} object - El objeto del cual se eliminarán las propiedades vacías
   * @returns {object} - El objeto formateado sin propiedades vacías
   */
  static removeEmptyProperties<T extends object>(object: T): Partial<T> {
    return Object.entries(object).reduce((acc, [key, value]) => {
      // Para objetos y arreglos se revisan cada una de sus propiedades o elementos
      if (typeof value === 'object' && value !== null) {
        // Para arreglos, mantenerlos si no están vacíos
        if (Array.isArray(value)) {
          if (value.length > 0) {
            acc[key as keyof T] = value as T[keyof T];
          }
        } 
        // Valida un objeto de manera recursiva
        else {
          const cleaned = this.removeEmptyProperties(value);
          if (Object.keys(cleaned).length > 0) {
            acc[key as keyof T] = cleaned as T[keyof T];
          }
        }
      } 
      // Para tipos primitivos, mantenerlos si no están vacíos, nulos o indefinidos
      else if (value !== undefined && value !== null && value !== '') {
        acc[key as keyof T] = value;
      }
      return acc;
    }, {} as Partial<T>);
  }

  /**
   * Elimina las propiedades duplicadas de un objeto en función de otro objeto.
   * @param {Object} objectToCheck - El objeto del que se deben eliminar las propiedades duplicadas.
   * @param {Object} baseObject - El objeto que se utilizará para comparar y eliminar las propiedades duplicadas de objectToCheck.
   * @returns {Object} - Un nuevo objeto con solo las propiedades que están presentes en objectToCheck pero no en baseObject.
   */
  static removeDuplicateProperties(objectToCheck: any, baseObject: any): any {
    return Object.keys(objectToCheck)
      .filter((key) => !baseObject.hasOwnProperty(key))
      .reduce((result: any, key: any) => {
        result[key] = objectToCheck[key];
        return result;
      }, {});
  }

  static CleaveFormat(ElementRef: any, Options: any) {
    return new Cleave(ElementRef, Options);
  }

  static getFileExtensionIcon(extension: string): string | undefined {
    for (const key in FILE_EXTENSIONS) {
      if (FILE_EXTENSIONS.hasOwnProperty(key)) {
        const extensions = FILE_EXTENSIONS[key];
        if (extensions.includes(extension)) {
          return key;
        }
      }
    }
    return 'fa-file';
  }

  static formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static async filesToBase64(files: File[]): Promise<string[]> {
    const base64Array: string[] = [];

    for (const file of files) {
      const base64Promise = new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });

      base64Array.push(await base64Promise);
    }

    return base64Array;
  }

  static convertObjectValuesToLowercase(obj: any): any {
    const result: any = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (value === null || value === undefined) {
          result[key] = value;
        } else if (typeof value === 'string') {
          result[key] = value.toLowerCase();
        } else if (typeof value === 'object') {
          result[key] = this.convertObjectValuesToLowercase(value);
        } else {
          result[key] = value;
        }
      }
    }

    return result;
  }

  static compareObjects(obj1: any, obj2: any): boolean {
    for (const prop in obj1) {
      if (obj1.hasOwnProperty(prop)) {
        if (!obj2.hasOwnProperty(prop) || obj1[prop] != obj2[prop]) {
          return false;
        }
      }
    }

    for (const prop in obj2) {
      if (obj2.hasOwnProperty(prop)) {
        if (!obj1.hasOwnProperty(prop)) {
          return false;
        }
      }
    }

    return true;
  }

  static convertToISODate(value: string | string[]): string {
    if (Array.isArray(value)) {
      return (
        DateTime.fromFormat(value[0].toString(), 'dd-MM-yyyy').toISODate() || ''
      );
    }
    return DateTime.fromFormat(value, 'dd-MM-yyyy').toISODate() || '';
  }

  static parseFilterDates(filter: any, dateKeys: string[]) {
    for (const key of dateKeys) {
      // Si el filtro tiene campos con fecha, se parsean como min y max
      if (filter.hasOwnProperty(key)) {
        const value = filter[key];
        if (Array.isArray(value) && value.length === 2) {
          filter[`${key}Min`] = DateTime.fromFormat(
            value[0],
            'dd-MM-yyyy'
          ).toISODate();
          filter[`${key}Max`] = DateTime.fromFormat(
            value[1],
            'dd-MM-yyyy'
          ).toISODate();
        } else if (typeof value === 'string') {
          filter[`${key}Min`] = DateTime.fromFormat(
            value,
            'dd-MM-yyyy'
          ).toISODate();
          filter[`${key}Max`] = DateTime.fromFormat(
            value,
            'dd-MM-yyyy'
          ).toISODate();
        } else if (Array.isArray(value) && value.length === 1) {
          filter[`${key}Min`] = DateTime.fromFormat(
            value[0],
            'dd-MM-yyyy'
          ).toISODate();
          filter[`${key}Max`] = DateTime.fromFormat(
            value[0],
            'dd-MM-yyyy'
          ).toISODate();
        }
        delete filter[key];
      }
    }

    return filter;
  }
}
