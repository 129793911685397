<app-indeterminate-loader *ngIf="loadingStates.loadingThread.value || loadingStates.loadingThreadMessages.value" [position]="'absolute'" [top]="'0'" [left]="'0'"></app-indeterminate-loader>
						
<div #messagesWrapper id="assistant-messages-wrapper"
    class="w-full max-h-full overflow-y-auto px-4 sm:px-6 md:px-5 py-6"
    style="overflow-anchor: auto; scroll-behavior: smooth;"
    infiniteScroll
    [infiniteScrollUpDistance]="0"
    [infiniteScrollThrottle]="150"
    [scrollWindow]="false"
    (scrolledUp)="fetchNextMessages()"
    >
    
    <!-- Info Text -->
    <div *ngIf="!thread && !messages[0] && !documents[0] && showInfo && !tutorialService.isTutorialActive('assistant-tour')" class="flex items-start mb-4 last:mb-0" style="overflow-anchor: none;">
        <img class="rounded-full mr-4 min-w-[2.5rem] min-h-[2.5rem] w-[2.5rem] h-[2.5rem]" src="https://dev.midespacho.cloud/assets/images/isotipo.png"/>
        <div>
            <div class="bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md mb-1">
                <div class="prose text-sm" [innerHTML]="infoContent"></div>
            </div>
        </div>
    </div>

    <!-- Msg loading -->
    <div *ngIf="loadingStates.loadingThreadMessages.value" class="w-full h-full flex items-center justify-center text-slate-400 mb-4" style="overflow-anchor: none">
        <svg class="animate-spin w-8 h-8 fill-current shrink-0" viewBox="0 0 16 16">
            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
        </svg>
    </div>

    <!-- Chat msg -->
    <ng-container *ngIf="!loadingStates.loadingThread.value">
        <app-dummy-chat *ngIf="tutorialService.isTutorialActive('assistant-tour')" />
        <ng-container *ngFor="let message of messages; let i = index">

            <!-- Date separator -->
            <div *ngIf="isNewDayMessage(message.created_at, messages[i - 1]?.created_at).condition" class="flex justify-center" style="overflow-anchor: none;">
                <div class="inline-flex items-center justify-center text-xs text-slate-600 font-medium px-2.5 py-1 bg-white border border-slate-200 rounded-full mb-5">
                    {{ isNewDayMessage(message.created_at, messages[i - 1]?.created_at).date }}
                </div>
            </div>

            <!-- Assistant -->
            <div *ngIf="message.role == 'assistant'" class="flex items-start mb-4 last:mb-0" style="overflow-anchor: none;">
                <img class="rounded-full mr-4 min-w-[2.5rem] min-h-[2.5rem] w-[2.5rem] h-[2.5rem]" src="https://dev.midespacho.cloud/assets/images/isotipo.png"/>
                <div>
                    <div class="bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md mb-1">
                        <div class="prose text-sm" *ngIf="message?.typedId" [id]="message?.typedId"></div>
                        <div class="prose text-sm" *ngIf="!message?.typedId" [innerHTML]="message.content"></div>
                    </div>
                    <!--
                    <div class="flex items-center justify-end">
                        <div class="text-xs text-slate-500 font-medium">
                            {{ isNewDayMessage(message.created_at, messages[i - 1]?.created_at).time }}
                        </div>
                    </div>
                    -->
                </div>
            </div>

            <!-- User -->
            <div *ngIf="message.role == 'user'" class="flex items-start mb-4 last:mb-0 justify-end" style="overflow-anchor: none;">
                <div>
                    <div [innerHTML]="message.content" class="prose text-sm bg-indigo-500 text-white p-3 rounded-lg rounded-tr-none border border-transparent shadow-md mb-1"></div>
                    <!--
                    <div class="flex items-center justify-end gap-4">
                        <div class="text-xs text-slate-500 font-medium">
                            {{ isNewDayMessage(message.created_at, messages[i - 1]?.created_at).time }}
                        </div>
                        <i *ngIf="message.loading" class="fa-duotone fa-loader animate-spin" style="font-size: 1rem"></i>
                        <i *ngIf="!message.loading && !message.error" class="fa-duotone fa-check" style="font-size: 1rem"></i>
                    </div>
                    -->
                </div>
                <div class="flex items-center ml-4 gap-4">
                    <img class="rounded-full min-w-[2.5rem] min-h-[2.5rem] w-[2.5rem] h-[2.5rem]" [src]="profilePicture"/>
                    <i *ngIf="message.error" (click)="retryMessage(message.id)" ngxTippy [ngxTippy]="'Ha ocurrido un error al enviar el mensaje.<br>Click aquí para intentar nuevamente.'" [tippyProps]="{ placement: 'top', allowHTML: true, hideOnClick: true }" class="fa-duotone fa-circle-exclamation cursor-pointer" style="font-size: 1.3rem"></i>
                </div>
            </div>

        </ng-container>
    </ng-container>

    <!-- Assistant processing -->
    <div *ngIf="this.loadingStates.sendingMessage.value" class="flex items-start mb-4 last:mb-0" style="overflow-anchor: none;">
        <img class="rounded-full mr-4" src="https://dev.midespacho.cloud/assets/images/isotipo.png" width="40" height="40" alt="User 01"/>
        <div>
            <div class="text-sm bg-white text-slate-800 p-3 rounded-lg rounded-tl-none border border-slate-200 shadow-md mb-1">
                <svg class="fill-current text-slate-400" viewBox="0 0 15 3" width="15" height="3">
                    <circle cx="1.5" cy="1.5" r="1.5">
                        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"></animate>
                    </circle>
                    <circle cx="7.5" cy="1.5" r="1.5">
                        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2"></animate>
                    </circle>
                    <circle cx="13.5" cy="1.5" r="1.5">
                        <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3"></animate>
                    </circle>
                </svg>
            </div>
        </div>
    </div>

    <div style="height: 1px; overflow-anchor: auto;"></div>
</div>

<div *ngIf="loadingStates.loadingThread.value" class="absolute top-0 left-0 w-full h-full flex items-center justify-center text-slate-400" disabled>
    <svg class="animate-spin w-8 h-8 fill-current shrink-0" viewBox="0 0 16 16">
        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
    </svg>
</div>