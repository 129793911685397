<!-- Indicator de carga de conversaciones -->
<app-indeterminate-loader *ngIf="loadingStates.loadingThreads.value" [position]="'relative'" [top]="'0'" [left]="'0'"></app-indeterminate-loader>

<!-- Conversaciones -->
<div #threadsWrapper class="px-5 py-4 flex-1 flex flex-col relative max-h-full overflow-x-hidden overflow-y-auto no-scrollbar" infiniteScroll [infiniteScrollDistance]="0" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="fetchNextThreads()" appResizeObserver (onResizeObserver)="checkThreadsScroll()">
    <div class="flex-1 flex flex-col max-h-full z-0">
        <div id="assistant-threads">
            <div class="flex justify-between items-center mb-3">
                <div class="text-xs font-semibold text-slate-400 uppercase">
                    Conversaciones
                </div>
                <button (click)="deactivateThread()" class="w-8 h-8 cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Nueva Conversación'">
                    <i class="fa-duotone fa-plus opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
                </button>
            </div>
            <div class="mb-6">
                <ul *ngIf="threads[0]" class="mb-3">
                    <!-- Thread falsa para el tutorial  -->
                    <li class="-mx-2 -intro-x pointer-events-none" *ngIf="tutorialService.isTutorialActive('assistant-tour')">
                        <div class="flex items-center justify-between w-full p-2 rounded bg-indigo-500/30">
                            <div class="flex items-center truncate w-full justify-between">
                                <div class="truncate">
                                    <span class="text-sm font-medium text-slate-800"></span>
                                    &nbsp;
                                </div>

                                <div class="truncate">
                                    <span class="text-sm font-medium text-slate-800">
                                        Nueva conversación
                                    </span>
                                    &nbsp;
                                </div>

                                <button class="w-8 h-8 min-w-8 min-h-8 cursor-pointer grid place-content-center p-1 rounded ml-2">
                                    <i class="fa-solid fa-ellipsis opacity-75 hover:opacity-100" style="font-size: 1rem;"></i>
                                </button>
                            </div>
                        </div>
                    </li>
                    <ng-container *ngFor="let iThread of threads; let i = index;">

                        <!-- Date separator -->
                        <li *ngIf="isNewDayMessage(iThread.created_at, threads[i - 1]?.created_at).condition" class="flex justify-center my-3 first:mt-0" style="overflow-anchor: none">
                            <div class="inline-flex items-center justify-center text-xs text-slate-600 font-medium px-2.5 py-1 bg-white border border-slate-200 rounded-full">
                                {{ isNewDayMessage(iThread.created_at, threads[i - 1]?.created_at).date }}
                            </div>
                        </li>

                        <!-- Thread -->
                        <li class="-mx-2 -intro-x" >
                            <button (click)="activateThread(iThread.threadId)" class="flex items-center justify-between w-full p-2 rounded" [ngClass]="{ 'bg-indigo-500/30': thread?.threadId == iThread.threadId }">
                                <div class="flex items-center truncate w-full justify-between">
                                    <div *ngIf="iThread?.typedId" class="truncate" >
                                        <span [id]="iThread?.typedId" class="text-sm font-medium text-slate-800"></span>
                                        &nbsp;
                                    </div>

                                    <div *ngIf="!iThread?.typedId" class="truncate" ngxTippy [tippyProps]="{ placement: 'auto', hideOnClick: true }" [ngxTippy]="iThread.name">
                                        <span class="text-sm font-medium text-slate-800">
                                            {{ iThread.name }}
                                        </span>
                                        &nbsp;
                                    </div>

                                    <button [menuTriggerFor]="threadOptions" appStopPropagation class="w-8 h-8 min-w-8 min-h-8 cursor-pointer grid place-content-center p-1 rounded ml-2" ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Opciones'">
                                        <i class="fa-solid fa-ellipsis opacity-75 hover:opacity-100" style="font-size: 1rem;"></i>
                                    </button>
                                    <ng-template #threadOptions>
                                        <ul class="uisr-dropdown-menu animate__animated animate__fadeIn animate__faster" cdkMenu>
                                            <li cdkMenuItem>
                                                <a role="button" class="app-menu-option-danger" (click)="deleteThread(iThread.threadId)">Eliminar</a>
                                            </li>
                                        </ul>
                                    </ng-template>
                                </div>
                                <!--
                                <div class="flex items-center ml-2">
                                    <div class="text-xs inline-flex font-medium bg-indigo-400 text-white rounded-full text-center leading-5 px-2">2</div>
                                </div>
                                -->
                            </button>
                        </li>

                    </ng-container>
                </ul>
                <div *ngIf="loadingStates.loadingThreads.value" class="w-full h-auto flex items-center justify-center text-slate-400">
                    <svg class="animate-spin w-8 h-8 fill-current shrink-0" viewBox="0 0 16 16">
                        <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z"></path>
                    </svg>
                </div>
            </div>
        </div>
        <div *ngIf="totalThreads == 0" class="flex-1 flex flex-col gap-4 items-center justify-center text-center">
            <i class="fa-solid fa-message-bot text-slate-400" style="font-size: 3rem"></i>
            <p class="text-sm">Parece que aún no has iniciado ninguna conversación</p>
        </div>
    </div>
</div>